import React, { useState } from 'react';

const ProductCard = ({ product }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const handleBuyClick = () => {
    window.location.href = product.amazonUrl;
  };

  return (
    <div className="border p-4 rounded-lg shadow-sm transition-all duration-300 hover:shadow-lg relative">
      <img 
        src={product.image} 
        alt={product.name} 
        className="w-full h-64 object-cover mb-4 rounded"
      />
      <h2 className="text-lg font-bold">{product.name}</h2>
      {product.description && (
        <p className="text-sm text-gray-600">{product.description.substring(0, 50)}...</p>
      )}
      <div className="flex items-center mt-4">
        <span className="text-xl font-bold text-green-600">₹{product.price}</span>
        {product.originalPrice && (
          <span className="text-sm text-gray-500 line-through ml-2">
            ₹{product.originalPrice}
          </span>
        )}
      </div>
      <div className="flex space-x-4 mt-4">
        <button 
          onClick={handleModalOpen}
          className="flex-1 bg-blue-600 text-white px-4 py-2 rounded-full hover:bg-blue-700 transition-colors"
        >
          Learn More
        </button>
        <button 
          onClick={handleBuyClick}
          className="flex-1 bg-green-600 text-white px-4 py-2 rounded-full hover:bg-green-700 transition-colors"
        >
          Buy Now
        </button>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-lg relative animate-slide-up">
            <button 
              onClick={handleModalClose}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
            >
              ✕
            </button>
            <img 
              src={product.image} 
              alt={product.name} 
              className="w-full h-48 object-cover rounded mb-4"
            />
            <h2 className="text-2xl font-bold mb-2">{product.name}</h2>
            <p className="text-sm text-gray-600 mb-4">{product.description}</p>
            {product.variant && (
              <p className="text-sm text-red-600 mb-4">Variants: {product.variant}</p>
            )}
            <div className="flex justify-between items-center">
              <span className="text-xl font-bold text-green-600">₹{product.price}</span>
              {product.originalPrice && (
                <span className="text-sm text-gray-500 line-through">
                  ₹{product.originalPrice}
                </span>
              )}
            </div>
            <button 
              onClick={handleBuyClick}
              className="w-full mt-4 bg-green-600 text-white px-4 py-2 rounded-full hover:bg-green-700 transition-colors"
            >
              Buy Now
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductCard;
