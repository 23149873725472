import React, { useState } from 'react';
import { Search } from 'lucide-react';
import naturemaxlogo from '../../images/naturmaxlogo.jpg'

const Header = () => {
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  return (
    <header className="bg-white sticky top-0 z-50 shadow-md transition-shadow">
      <div className="container mx-auto px-4 py-3">
        <div className="flex items-center justify-between gap-8">
          <div className="flex items-center space-x-4">
            <div className="flex-shrink-0">
              <img
                src={naturemaxlogo}
                alt="Naturemax Logo"
                className="h-14 transform transition-transform hover:scale-105"
              />
            </div>
            <h1 className="text-3xl font-bold bg-gradient-to-r from-green-600 to-green-400 bg-clip-text text-transparent hover:from-green-500 hover:to-green-300 transition-colors duration-300">
              NatureMax
            </h1>
          </div>

          <div className="flex-1 relative group max-w-2xl mx-auto">
            <div className={`absolute inset-y-0 left-4 flex items-center pointer-events-none transition-transform duration-200 ${
              isSearchFocused ? 'scale-90' : ''
            }`}>
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Search for products..."
              className={`w-full pl-12 pr-4 py-3 rounded-xl border-2 transition-all duration-200 focus:outline-none ${
                isSearchFocused
                  ? 'border-green-500 bg-white shadow-lg'
                  : 'border-gray-200 bg-gray-50'
              }`}
              onFocus={() => setIsSearchFocused(true)}
              onBlur={() => setIsSearchFocused(false)}
            />
          </div>

          <div className="flex items-center space-x-6">
            <a 
              href="/retailers"
              className="px-6 py-2.5 bg-green-600 text-white font-semibold rounded-lg hover:bg-green-500 transition-colors duration-200 shadow-md hover:shadow-lg transform hover:-translate-y-0.5"
              data-test="cta"
              data-test-cta="custom"
            >
              Find Retailers
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;