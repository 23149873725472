export const products = [
  {
    id: 1,
    name: "Nature Spa Clean & Care Nature Star Anti acne Face Wash",
    description: "For Acne care,reduce pigmentation,remove Blackheads provide Supirior Skin Nourishment with Natural Ingredients",
    variant: "200ml",
    price: 199,
    originalPrice: 349,
    image: "https://m.media-amazon.com/images/I/71-vJhMH+aL._SX679_.jpg",
    amazonUrl: "https://amzn.in/d/2SXrVfR"
  },
  {
    id: 2,
    name: "Nature Spa Professional Nature Star Intense Repairing shampoo help to fizz control",
    description: "Hair Nourishment with Natural Ingredients",
    variant: "200ml",
    price: 199,
    originalPrice: 349,
    image: "https://m.media-amazon.com/images/I/71YXaXVztdL._SX679_.jpg",
    amazonUrl: "https://amzn.in/d/hG70YoH"
  },
  {
    id: 3,
    name: "Nature Spa Professional Nature Star Intense Repairing Conditioner",
    description: "for Hair Nourishment with 10 Star Miraculous Ingredients",
    variant: "300ml",
    price: 299,
    originalPrice: 499,
    image: "https://m.media-amazon.com/images/I/71Y9dsgjuqL._SX679_.jpg",
    amazonUrl: "https://amzn.in/d/6i9ciWL"
  },
  {
    id: 4,
    name: "NATUREMAX PROFESSIONAL HAIR FALL DEFENCE SHAMPOO",
    description: "Hair Nourishment with Natural Ingredients",
    variant: "300ml",
    price: 399,
    originalPrice: 599,
    image: "https://m.media-amazon.com/images/I/71zWSclANJL._SX679_.jpg",
    amazonUrl: "https://amzn.in/d/bW9uoEq"
  },
  {
    id: 5,
    name: "Nature Spa Professional Nature Star Intense Repairing shampoo help to fizz control",
    description: "Hair Nourishment with Natural Ingredients",
    variant: "200ml",
    price: 199,
    originalPrice: 349,
    image: "https://m.media-amazon.com/images/I/513Foqqp7fL._SX679_.jpg",
    amazonUrl: "https://amzn.in/d/hG70YoH"
  }
];
