const TopBar = () => (
    <div className="bg-gradient-to-r from-green-50 to-green-100 text-center py-3 text-sm transform transition-all hover:from-green-100 hover:to-green-200">
      <div className="container mx-auto px-4 flex items-center justify-center space-x-2 animate-fade-in">
        <div className="flex items-center bg-white px-4 py-1 rounded-full shadow-sm">
          <span className="font-medium text-green-600">Special Offer</span>
        </div>
        <span>Get Flat 50% OFF on shopping of More than 5 products</span>
      </div>
    </div>
  );

  export default TopBar;