import TopBar from '../components/layout/TopBar.jsx';
import Header from '../components/layout/Header.jsx';
import Navigation from '../components/layout/Navigation.jsx';
import ProductCard from '../components/products/ProductCard.jsx';
import ProductList from '../components/products/ProductList.jsx';
const HomePage = () => {
    const products = [];
    return (
      <div className="min-h-screen bg-gray-50">
        <TopBar />
        <Header />
        <Navigation />
        <main className="container mx-auto px-4 py-12">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">All Products</h1>
            <div className="w-24 h-1 bg-green-600 mx-auto rounded-full" />
          </div>
        <ProductList/>
        
       
  
          {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {products.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div> */}
        </main>
      </div>
    );
  };
  
  export default HomePage;