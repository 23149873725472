import React from 'react';
import { ExternalLink } from 'lucide-react';
import Header from '../../src/components/layout/Header.jsx';
import flipkartlogo from '../images/flipkartlogo.png';
import amazonlogo from '../images/amazonlogo.png';

const RetailersPage = () => {
  const retailers = [
    {
        name: 'Amazon',
        logo: amazonlogo,
        link: 'https://www.amazon.in/stores/page/D7747098-5B9F-4CC5-91BC-29538D54D92A'
      },
    {
      name: 'Flipkart',
      logo: flipkartlogo,
      link: 'https://www.flipkart.com/search?q=nature%20spa&otracker=search&otracker1=search&marketplace=FLIPKART&as-show=on&as=off'
    },
    {
      name: 'Meesho',
      logo: 'https://www.meesho.com/assets/svgicons/meeshoLogo.svg',
      link: 'https://www.meesho.com/nature-spa-care-protect-brightening-face-wash/p/7t069d?srsltid=AfmBOoqJVsCFoyzfvhBWr9kHEDAs4kGsgfoSXJE8RFtAZBOtjS0lDwwr'
    }
  ];

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-green-50 to-white">
      <Header />
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-12">
          <h1 className="text-4xl font-bold text-center text-gray-800 mb-12">
            Our authorised retailers
          </h1>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {retailers.map((retailer) => (
              <a
                key={retailer.name}
                href={retailer.link}
                target="_blank"
                rel="noopener noreferrer"
                className="group"
              >
                <div className="bg-white rounded-xl p-8 shadow-md hover:shadow-xl transition-shadow duration-300 flex flex-col items-center justify-center min-h-[200px] relative">
                  <img
                    src={retailer.logo}
                    alt={`${retailer.name} logo`}
                    className="max-w-[180px] h-auto object-contain mb-4"
                  />
                  <ExternalLink className="absolute top-4 right-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity duration-200" size={20} />
                </div>
              </a>
            ))}
          </div>

          <p className="text-center text-gray-600 mt-12 max-w-2xl mx-auto">
            These are our officially authorized retail partners. For the best shopping experience and authentic products, please make your purchases through these trusted retailers.
          </p>
        </div>
      </main>
    </div>
  );
};

export default RetailersPage;