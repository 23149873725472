import { CgChevronDownR } from "react-icons/cg";
const Navigation = () => {
    const navItems = [
      { name: 'ALL PRODUCTS', isNew: false },
      { name: 'HAIR CARE', isNew: true },
      { name: 'SKIN CARE', isNew: true },
      // { name: 'BODY CARE', isNew: false },
      // { name: 'BESTSELLERS', isNew: false },
      // { name: 'OFFERS', isNew: true },
    ];
  
    return (
      <nav className="bg-white border-b">
        <div className="container mx-auto px-4">
          <div className="flex justify-center space-x-8">
            {navItems.map((item, index) => (
              <button
                key={index}
                className="relative group py-4 px-2"
              >
                <div className="flex items-center space-x-1">
                  <span className="text-sm font-medium hover:text-green-600 transition-colors">
                    {item.name}
                  </span>
                  {item.isNew && (
                    <span className="bg-red-500 text-white text-xs px-2 py-0.5 rounded-full animate-pulse">
                      New
                    </span>
                  )}
                  {['SKIN CARE', 'HAIR CARE', 'MAKEUP'].includes(item.name) && (
                    <CgChevronDownR className="w-4 h-4 group-hover:text-green-600 transition-colors" />
                  )}
                </div>
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-green-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200" />
              </button>
            ))}
          </div>
        </div>
      </nav>
    );
  };
  export default Navigation;
