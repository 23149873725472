import React from 'react';
import ProductCard from '../products/ProductCard';
import { products } from '../../data/products';
const ProductList = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
      {products.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  );
};

export default ProductList;
